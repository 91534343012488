import { Sendform } from '../libs/sendform/sendform2';
import { Slider } from '../libs/slider';
import '../../node_modules/select2/dist/js/select2.min.js';
//JQuery Mask
import '../libs/maskedinput.min.js';
import '../../node_modules/magnific-popup/dist/jquery.magnific-popup.js';

$( document ).ready(function() {
    let projectApp = new App();
    global.app = projectApp;
    projectApp.init();
});


class App{
    // Define global visible variable inside app 
    constructor(){}
    init(){

        //Fixed header on mobile
        $(window).scroll(function(){
            let header = $('.header'),
                scroll = $(window).scrollTop()
            if (scroll >= 60) header.addClass('__fixed')
            else header.removeClass('__fixed')
        })

        //Scroll
        let $links = $('.js_scroll-lnk');
        $links.each(function() {
            $(this).click(function(){
                $links.each(function() {
                    $(this).removeClass('__active')
                })
                $(this).addClass('__active')
                $('html, body').animate({
                    scrollTop: $( $(this).attr('href') ).offset().top
                }, 1000)
                return false
            })
        })

        //Select
        $('.js_select').each(function(){
            let $thisSelect = $(this)
            $thisSelect.select2({
                minimumResultsForSearch: Infinity,
                dropdownParent: $thisSelect.parent()
            });
        })


        //Mobile menu
        $('body').on('click', '.js_menu-btn', function(e) {
            e.preventDefault()
            $(this).toggleClass('__active')
            if($(this).hasClass('__active')){
                $('.js_menu-cnt').addClass('__show-menu')
            } else{
                $('.js_menu-cnt').removeClass('__show-menu')
            }
        })


        this.accordion('.js_accordion-ttl', '.js_accordion-cnt', '__active')

        //Slider for main block
        new Slider('.js_carousel-main',
            '.js_carousel-main-prev',
            '.js_carousel-main-next',
            true, {
            loop: true,
            margin: 0,
            items: 1,
            nav: false,
            autoHeight: false,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            dots: false,
            paginationNumbers: true
        })

        //Slider for about block
        new Slider('.js_carousel-gallery',
            '.js_carousel-gallery-prev',
            '.js_carousel-gallery-next',
            false, {
                loop: true,
                margin: 0,
                items: 1,
                nav: false,
                autoHeight: false,
                autoplay: true,
                autoplayTimeout: 5000,
                autoplayHoverPause: true,
                dots: false
            })

        //Slider for project block
        new Slider('.js_carousel-project',
            '.js_carousel-project-prev',
            '.js_carousel-project-next',
            false, {
                loop: true,
                margin: 0,
                items: 1,
                nav: false,
                autoHeight: false,
                autoplay: true,
                autoplayTimeout: 5000,
                autoplayHoverPause: true,
                dots: true
        })

        //PhoneMask init
        this.initPhoneMask()

        //Send form
        new Sendform('.js_send-form')
        new Sendform('.js_send-form-popup')

        this.openPopup('.js_popup-open', '#modal-consultation')
        this.closePopup('.js_popup-close')

    }

    accordion(accordionTtl, accordionCnt, activeClassTtl ){
        $('body').on('click', accordionTtl, function (event) {
            event.preventDefault();
            if($(accordionTtl).hasClass(activeClassTtl) && !$(this).hasClass(activeClassTtl)){
                $(accordionTtl).removeClass(activeClassTtl);
                $(accordionCnt).slideUp(400);
            }
            $(this).toggleClass(activeClassTtl);
            $(this).next(accordionCnt).slideToggle(400);
        })
    }

    initPhoneMask() {
        $('input[type="phone"]').mask('+38 (999) 999-99-99');
    }

    openPopup(btn, modalEL) {
        $('body').on('click', btn, function(event) {
            event.preventDefault()
            $.magnificPopup.open({
                showCloseBtn: false,
                type: 'inline',
                tLoading: 'Загрузка...',
                items: {
                    src: modalEL
                }
            })
        })
    }

    closePopup(el) {
        $('body').on('click', el, function(event) {
            event.preventDefault();
            $.magnificPopup.close();
        })
    }

};
